<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="toolbar-header">
        <card-change-company
          class="mx-1"
          :selectedCompany="selectedCompany"
          :isHideSome="!isOnScrollTop"
          @refreshData="initialRender"
        />
        <div class="d-flex align-center px-2 my-2">
          <ion-buttons slot="start">
            <ion-back-button
              color="primary"
              mode="ios"
              text=""
              default-href="/b2b/account"
              @click="goBack"
              :icon="chevronBackOutline"
            ></ion-back-button>
          </ion-buttons>
          <ion-label class="fw-600 pl-1 fs-3" @click="goBack">{{ $t('all_invoices') }}</ion-label>
        </div>
        <div class="d-flex align-center px-4" v-if="isRenderCompletePage">
          <checkbox :checked="checkedAll" :disabled="isDisabledAllCheck()" @onToggle="onSelectAllInvoices" />
          <ion-label class="pl-2 py-2">{{ $t('select_all') }}</ion-label>
        </div>
      </ion-toolbar>
    </ion-header>
    <ion-content ref="contentRef" :scroll-events="true" @ionScroll="handleScroll($event)">
      <ion-refresher slot="fixed" @ionRefresh="handleRefreshAndRefetchData($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <pull-refresh v-if="!isOnline"></pull-refresh>
      <comp-not-found
        v-if="isNotFoundInvoices"
        :headerLabel="$t('no_invoices')"
        :subHeaderLabel="$t('browse_your_business')"
        :actionLabel="$t('browse_product')"
        @callbackAction="goBrowseProduct"
      />
      <comp-banner-invoices
        v-if="isRenderCompletePage"
        :totalUnpaidInvoices="totalUnpaidInvoices"
        :unpaidAmount="unpaidAmount"
        :currencySymbol="currencySymbol"
        :availableCreditAmount="availableCreditAmount"
        :isSalesUser="false"
      />
      <div v-if="isRenderCompletePage">
        <card-invoices
          v-for="invoice in cardInvoices"
          :key="invoice.invoiceId"
          :invoice="{ ...invoice, tenantName }"
          @onSelection="onSelectSingleInvoice"
          @onEnterViewInvoice="onEnterViewInvoice(invoice)"
        />
      </div>
      <skeleton-card v-else-if="isLoadingSkeleton" />
      <ion-infinite-scroll
        @ionInfinite="loadMore($event)"
        threshold="100px"
        id="infinite-scroll"
        :disabled="isDisabledLoadMore"
      >
        <ion-infinite-scroll-content loading-spinner="bubbles" loading-text="Loading ...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>

    <comp-bottom-content
      v-if="isRenderCompletePage"
      :currencySymbol="currencySymbol"
      :totalInvoiceAmount="totalInvoiceAmount"
      :totalSelectedInvoices="totalSelectedInvoices"
      @handle-pay-invoices="handlePayInvoices"
    />
    <skeleton-bottom v-else-if="isLoadingSkeleton" />

    <ion-modal
      mode="ios"
      :is-open="isOpenViewInvoiceAndCreditNote"
      @didDismiss="setOpenViewInvoiceAndCreditNote(false)"
    >
      <invoice-and-credit-note
        :detailOrderId="invoiceSelected.orderId"
        :invoiceId="invoiceSelected.invoiceId"
        :currencySymbol="currencySymbol"
        @close-page="setOpenViewInvoiceAndCreditNote(false)"
      />
    </ion-modal>
  </ion-page>
</template>
<script>
// packages
import { ACTIONS } from '@/modules/b2b/store/payment/actions';
import { useAlert } from '@/modules/shared/utils/';
import { useBackButton } from '@ionic/vue';
import { chevronBackOutline } from 'ionicons/icons';
import debounce from 'lodash.debounce';
import { defineAsyncComponent, defineComponent, nextTick, ref } from 'vue';
import { useRouter } from 'vue-router';
import { createNamespacedHelpers } from 'vuex';
import InvoiceAndCreditNote from '../../order/components/InvoiceAndCreditNoteList';

// components
import Checkbox from '@/components/Checkbox.vue';
import CardChangeCompany from '@/modules/shared/components/CardChangeCompany.vue';
import CompNotFound from '@/modules/shared/components/CompNotFound.vue';
import {
  CardInvoices,
  CompBannerInvoices,
  CompBottomContent,
  SkeletonBottom,
  SkeletonCard
} from '@/modules/shared/components/invoices-payment';

const { mapGetters, mapActions } = createNamespacedHelpers('b2b/payment');

export default defineComponent({
  name: 'select-invoices-b2b',
  inject: ['$storage'],
  components: {
    CompBottomContent,
    CardInvoices,
    Checkbox,
    SkeletonCard,
    SkeletonBottom,
    CompBannerInvoices,
    CardChangeCompany,
    CompNotFound,
    InvoiceAndCreditNote,
    PullRefresh: defineAsyncComponent(() => import('@/modules/shared/views/refreshPage/PullRefresh.vue'))
  },
  setup() {
    const noLoadItems = ref(false);
    const router = useRouter();
    const { createAlert } = useAlert();
    const isOpenRefLoading = ref(false);
    const isOnScrollTop = ref(false);
    const setOpenLoading = (state) => (isOpenRefLoading.value = state);
    const goBrowseProduct = () => {
      router.push({ path: '/b2b/main/home' }, () => {}, { replace: true });
    };
    const goBack = () => {
      const stateBackPath = router.options.history.state.back;
      nextTick(() => {
        stateBackPath === '/b2b/account' ||
        stateBackPath === '/b2b/select-customer' ||
        stateBackPath === '/b2b/invoices/select-invoices' ||
        stateBackPath === '/b2b/invoices/select-payment' ||
        stateBackPath === '/b2b/payment-details'
          ? router.push({ path: '/b2b/account' }, () => {}, { replace: true })
          : router.back();
      });
    };
    useBackButton(10, () => {
      goBack();
    });
    const checkedAll = ref(true);
    const isOpenViewInvoiceAndCreditNote = ref(false);
    const setOpenViewInvoiceAndCreditNote = (state) => (isOpenViewInvoiceAndCreditNote.value = state);
    const invoiceSelected = ref(null);
    const isOnline = ref(true);
    const detectConnection = () => {
      const connection = navigator.onLine;
      if (connection) {
        isOnline.value = true;
      } else {
        isOnline.value = false;
      }
    };
    const handleRefresh = async (event) => {
      await detectConnection();
      event.target.complete();
    };
    return {
      handleRefresh,
      isOnline,
      isOpenRefLoading,
      setOpenLoading,
      chevronBackOutline,
      goBack,
      goBrowseProduct,
      noLoadItems,
      checkedAll,
      user: ref(null),
      selectedCompany: ref(null),
      cardInvoices: ref([]),
      selectedCardInvoices: ref([]),
      totalInvoiceAmount: ref(0),
      totalSelectedInvoices: ref(0),
      isDisabledLoadMore: ref(false),
      tenantName: ref(''),
      setOpenViewInvoiceAndCreditNote,
      isOpenViewInvoiceAndCreditNote,
      invoiceSelected,
      createAlert,
      isOnScrollTop,
      handlePayInvoices: () => {
        let path = '/b2b/invoices/select-payment';
        router.push(path);
      },
      onEnterViewInvoice: (invoiceArg) => {
        invoiceSelected.value = invoiceArg;
        setOpenViewInvoiceAndCreditNote(true);
      },
      handleScroll: debounce((e) => {
        const onScrollHeightValue = e.detail.scrollTop;
        if (onScrollHeightValue > 10) isOnScrollTop.value = true;
        if (onScrollHeightValue === 0) isOnScrollTop.value = false;
      }, 200)
    };
  },
  computed: {
    ...mapGetters([
      'listInvoices',
      'status',
      'error',
      'totalCountInvoices',
      'xeroCustomer',
      'totalUnpaidInvoices',
      'isFinanceModuleEnabled'
    ]),
    isLoadingSkeleton() {
      return this.isOpenRefLoading;
    },
    isNotFoundInvoices() {
      return (
        (!this.isOpenRefLoading && this.cardInvoices.length === 0) ||
        (!this.isOpenRefLoading && !this.isFinanceModuleEnabled)
      );
    },
    isRenderCompletePage() {
      return (
        !this.isOpenRefLoading &&
        this.cardInvoices.length >= 1 &&
        this.isFinanceModuleEnabled &&
        this.isOnline
      );
    },
    currencySymbol() {
      return this.user && this.user.currency_symbol;
    },
    availableCreditAmount() {
      const credit =
        Number(this.xeroCustomer?.overpayment_amount || 0) +
        Number(this.xeroCustomer?.credit_notes_amount || 0);
      return credit > 0 ? credit : 0;
    },
    unpaidAmount() {
      return Number(this.xeroCustomer?.outstanding_amount || 0);
    }
  },
  async mounted() {
    await this.initialRender();
  },
  async ionViewDidEnter() {
    if (!this.$router.options.history.state.forward) {
      await this.initialRender();
    }
  },
  methods: {
    ...mapActions([
      ACTIONS.GET_CUSTOMER_INVOICES,
      ACTIONS.RESET_STATUS_ERROR,
      ACTIONS.LOAD_MORE_CUSTOMER_INVOICES,
      ACTIONS.GET_XERO_CUSTOMER,
      ACTIONS.GET_TOTAL_UNPAID_INVOICES,
      ACTIONS.SET_SELECTED_PAYMENT_INVOICES,
      ACTIONS.CHECK_IS_FINANCE_MODULE_ENABLED
    ]),
    async initialRender() {
      this.setOpenLoading(true);
      this.user = await this.$storage.getUser();
      this.tenantName = this.user.tenant.tenant.name;
      this.selectedCompany = await this.$storage.getSelectedCompany();
      await this.checkIsFinanceModuleEnabled();
      if (!this.isFinanceModuleEnabled) {
        this.setOpenLoading(false);
        return;
      }
      await this.getXeroCustomer();
      await this.getTotalUnpaidInvoices();
      await this.getInvoices();
      this.isDisabledLoadMore = false;
      this.setOpenLoading(false);
    },
    async checkIsFinanceModuleEnabled() {
      await this[ACTIONS.CHECK_IS_FINANCE_MODULE_ENABLED]({
        tenantId: this.user.tenant.id,
        customerId: this.selectedCompany.id
      });
    },

    async getXeroCustomer() {
      await this[ACTIONS.GET_XERO_CUSTOMER]({
        tenantId: this.user.tenant.id,
        customerId: this.selectedCompany.id
      }).then(() => {
        if (this.error || !this.status) {
          this.handleApiError(this.error.message);
        }
      });
    },
    async getTotalUnpaidInvoices() {
      await this[ACTIONS.GET_TOTAL_UNPAID_INVOICES]({
        tenantId: this.user.tenant.id,
        customerId: this.selectedCompany.id
      }).then(() => {
        if (this.error || !this.status) {
          this.handleApiError(this.error.message);
        }
      });
    },
    async getInvoices() {
      await this[ACTIONS.GET_CUSTOMER_INVOICES]({
        paramsListInvoices: {
          tenantId: this.user.tenant.id,
          customerId: this.selectedCompany.id,
          offset: 0
        }
      }).then(() => {
        if (this.error || !this.status) {
          this.handleApiError(this.error.message);
          return;
        }
        this.setCheckAllInvoices();
        this.initCardInvoices();
        this.handleSelectedInvoice();
      });
    },
    handleApiError(errorMessage) {
      this.createAlert(this.$t('failed'), errorMessage);
      this[ACTIONS.RESET_STATUS_ERROR]();
    },
    loadMore(e) {
      try {
        if (this.noLoadItems) return;
        this.noLoadItems = true;
        if (this.listInvoices.invoices.length === this.totalCountInvoices) {
          this.isDisabledLoadMore = true;
          return;
        }
        this[ACTIONS.LOAD_MORE_CUSTOMER_INVOICES]({
          paramsListInvoices: {
            tenantId: this.user.tenant.id,
            customerId: this.selectedCompany.id,
            offset: this.listInvoices.invoices.length,
            isIncludeTotalCount: false
          },
          onFinish: async () => {
            await this.getTotalUnpaidInvoices();
            await this.initCardInvoices();
            await this.handleSelectedInvoice();
            e.target.complete();
          }
        });
      } catch (error) {
        this.noLoadItems = true;
        this.handleApiError(error.message);
      } finally {
        this.noLoadItems = false;
      }
    },
    setCheckAllInvoices() {
      this.checkedAll = this.listInvoices.invoices.some((item) => !item.is_paid && !item.is_pending_approval);
    },
    initCardInvoices() {
      if (!this.listInvoices.invoices) return;
      this.cardInvoices = this.listInvoices.invoices.map((i) => {
        const isDisabled = i.is_paid || i.is_pending_approval;
        const invoiceId = i.invoice.id;
        const debitNoteId = i.is_debit_note ? i.debit_note.id : null;
        let isSelected = false;
        if (!this.checkedAll) {
          isSelected =
            this.cardInvoices.find(
              (item) => item.invoiceId === invoiceId && item.debitNoteId === debitNoteId && item.isSelected
            )?.isSelected || false;
        }
        return {
          invoiceId,
          debitNoteId,
          customer: i.invoice.company_name,
          invoiceNumber: i.is_debit_note ? i.debit_note.debit_note_number : i.invoice.invoice_number,
          amount: i.is_debit_note ? i.debit_note.amount : i.invoice.amount,
          outstandingAmount: i.is_debit_note ? i.debit_note.outstanding_amount : i.invoice.outstanding_amount,
          currencySymbol: this.currencySymbol,
          dueDate: i.invoice.due_date,
          invoiceDate: i.invoice.actual_delivery_date,
          creditTerm: i.invoice.credit_term,
          creditTermName: i.invoice.credit_term_display_name,
          isSelected: !this.checkedAll ? isSelected : !isDisabled,
          isDisabled: isDisabled,
          isPaid: i.is_paid,
          isOverdue: i.is_overdue,
          isDueToday: i.is_due_today,
          isPendingApproval: i.is_pending_approval,
          paymentTypeId: i.payment_type_id,
          isHaveCreditNote: i.invoice.credit_note?.length > 0,
          orderId: i.invoice.OrderItem?.[0]?.order_id || null
        };
      });
    },
    thereIsUnpaidInvoices() {
      const unpaidInv = this.cardInvoices.filter((i) => !i.isPaid && !i.isPendingApproval);
      return unpaidInv.every((i) => i.isSelected);
    },
    isDisabledAllCheck() {
      return this.cardInvoices.every(({ isDisabled }) => isDisabled);
    },
    onSelectAllInvoices(checked) {
      if (checked) {
        this.cardInvoices = this.cardInvoices.map((i) => {
          if (!i.isDisabled) i.isSelected = true;
          return i;
        });
      } else {
        if (this.thereIsUnpaidInvoices()) {
          this.cardInvoices = this.cardInvoices.map((i) => {
            i.isSelected = false;
            return i;
          });
        }
      }
    },
    async onSelectSingleInvoice(invoiceId, debitNoteId, checked) {
      const invoiceIdx = this.cardInvoices.findIndex(
        (i) => i.invoiceId === invoiceId && i.debitNoteId === debitNoteId
      );
      if (this.cardInvoices[invoiceIdx]) {
        this.cardInvoices[invoiceIdx].isSelected = checked;
        if (this.thereIsUnpaidInvoices()) this.checkedAll = true;
        else this.checkedAll = false;
        await this.handleSelectedInvoice();
      }
    },
    async handleSelectedInvoice() {
      const selectedInvoices = this.cardInvoices.filter((i) => !i.isDisabled && i.isSelected);
      const totalInvoiceAmount = selectedInvoices.reduce((acc, i) => acc + i.outstandingAmount, 0);
      this.selectedCardInvoices = [...selectedInvoices];
      this.totalInvoiceAmount = +totalInvoiceAmount.toFixed(2);
      this.totalSelectedInvoices = selectedInvoices.length;
      await this[ACTIONS.SET_SELECTED_PAYMENT_INVOICES]({
        selectedInvoices: this.selectedCardInvoices,
        totalInvoiceAmount: this.totalInvoiceAmount,
        availableCreditAmount: this.availableCreditAmount
      });
    },
    async handleRefreshAndRefetchData(event) {
      await this.handleRefresh(event);
      if (this.isOnline) {
        await this.getXeroCustomer();
        await this.getTotalUnpaidInvoices();
        await this.getInvoices();
      }
    }
  }
});
</script>
<style lang="scss" scoped></style>
